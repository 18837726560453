<template>
  <div class="supplierManagement" v-loading="loading">
    <div class="globle_border content">
      <div class="right">
        <el-select v-model="purchaseStatus" placeholder="接单状态" clearable @change="onCurrentPage()">
          <el-option v-for="item in orderTakingData" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="signStatus" placeholder="签收状态" clearable @change="onCurrentPage()">
          <el-option v-for="item in signForData" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="shippingStatus" placeholder="发货状态" clearable @change="onCurrentPage()">
          <el-option v-for="item in selectData" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-date-picker v-model="formInline.time" @change="gettime" value-format="yyyy-MM-dd" type="daterange"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" popper-class="sift-data"></el-date-picker>
        <el-input placeholder="请输入采购单号" suffix-icon="el-icon-search" v-model="purchaseNum" :clearable="true"
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商超名称" suffix-icon="el-icon-search" v-model="shopName" :clearable="true"
          @change="onCurrentPage()"></el-input>
      </div>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData" style="width: 100%">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
              <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="purchaseNum" label="采购单号" show-overflow-tooltip min-width="130" align="center">
          </el-table-column>
          <el-table-column prop="purchaseName" label="采购单名称" show-overflow-tooltip min-width="220" align="center">
          </el-table-column>
          <el-table-column prop="shopName" label="商超名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column label="接单状态" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              <div>
                {{ orderTakingStatus[scope.row.purchaseStatus] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="发货状态" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              <div>
                {{ selectStatus[scope.row.shippingStatus] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="签收状态" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              <div>
                {{ signForStatus[scope.row.signStatus] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="下单时间" show-overflow-tooltip min-width="180" align="center">
          </el-table-column>
          <el-table-column prop="signTime" label="签收时间" show-overflow-tooltip min-width="180" align="center">
          </el-table-column>
          <el-table-column label="操作" header-align="center" fixed="right" min-width="100" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="small" v-if="scope.row.purchaseStatus == 0"
                @click="handleCommand(scope.row)">接单</el-button>
              <el-button type="primary" size="small"
                v-else-if="scope.row.purchaseStatus == 1 && scope.row.shippingStatus == 0"
                @click="handleCommand(scope.row)">发货</el-button>
              <el-button v-else type="primary" size="small" @click="handleCommand(scope.row)" plain>查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [],
      // 发货状态
      selectStatus: {
        0: "待发货",
        1: "已发货",
        2: "已取消",
      },
      // 接单状态
      orderTakingStatus: {
        0: "待接单",
        1: "已接单",
        2: "已取消",
      },
      // 签收状态
      signForStatus: {
        0: "待签收",
        1: "已完成",
        2: "已取消",
      },

      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      // 发货状态
      selectData: [
        {
          value: "0",
          label: "待发货",
        },
        {
          value: "1",
          label: "已发货",
        },
        {
          value: "2",
          label: "已取消",
        },
      ],
      // 接单状态
      orderTakingData: [
        {
          value: "0",
          label: "待接单",
        },
        {
          value: "1",
          label: "已接单",
        },
        {
          value: "2",
          label: "已取消",
        },
      ],
      // 签收状态
      signForData: [
        {
          value: "0",
          label: "待签收",
        },
        {
          value: "1",
          label: "已签收",
        },
        {
          value: "2",
          label: "已取消",
        },
      ],
      purchaseStatus: "", //接单状态
      shippingStatus: "", //发货状态
      signStatus: "", //签收状态
      purchaseNum: "", //采购单号
      shopName: "", //商超名称
      startTime: "", //开始时间
      endTime: "", //结束时间
      formInline: {
        time: [],
      }, //日期区间
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("productList"))
    this.purchaseStatus = obj?.purchaseStatus
    this.shippingStatus = obj?.shippingStatus
    this.signStatus = obj?.signStatus
    this.purchaseNum = obj?.purchaseNum
    this.shopName = obj?.shopName
    this.startTime = obj?.startTime
    this.endTime = obj?.endTime
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("productList", {})
    // 获取列表
    this.getRequest()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true
      this.$axios
        .get(this.$api.sendOrderList, {
          params: {
            // purchaseStatus: this.purchaseStatus || null, //状态：0待接单，1待发货，2已发货，3已完成
            purchaseStatus: this.purchaseStatus || null, //接单状态
            shippingStatus: this.shippingStatus || null, //发货状态
            signStatus: this.signStatus || null, //签收状态
            startTime: this.startTime || null, //开始时间
            endTime: this.endTime || null, //结束时间
            shopName: this.shopName || null, //商超名称
            purchaseNum: this.purchaseNum || null, //采购单号
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.totalCount = result.totalCount
            this.tableData = result.list
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 时间区间搜索
    gettime() {
      this.startTime = this.formInline.time ? this.formInline.time[0] || "" : ""
      this.endTime = this.formInline.time ? this.formInline.time[1] || "" : ""
      this.onCurrentPage()
      // console.log('开始时间:', this.startTime, '结束时间:', this.endTime);
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.tableData = []
      this.getRequest()
    },
    // 点击跳转
    handleCommand(row) {
      let obj = {
        purchaseStatus: this.purchaseStatus,
        shippingStatus: this.shippingStatus,
        signStatus: this.signStatus,
        purchaseNum: this.purchaseNum,
        shopName: this.shopName,
        startTime: this.startTime,
        endTime: this.endTime,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("productList", obj)


      // console.log(row);
      if (row.purchaseStatus == 0) {
        // console.log('跳转接单详情',);
        this.$router.push({ name: "purchaseOrderDet", query: { purchaseId: row.purchaseId } })
      } else if (row.purchaseStatus == 1 && row.shippingStatus == 0) {
        // console.log('跳转发货详情',);
        this.$router.push({ name: "waitDeliveryDet", query: { purchaseId: row.purchaseId } })
      } else {
        // console.log('跳转已完成详情',);
        this.$router.push({ name: "completeDet", query: { purchaseId: row.purchaseId } })
      }
    },
    // 状态文字颜色
    getStatusColor(status) {
      if (status == 0) {
        return "#FF0000"
      } else if (status == 1) {
        return "#FF0000"
      } else {
        return ""
      }
    },
    //按钮显示字样
    // getBtnStatus(status) {
    //   if (status == 0) {
    //     return '接单';
    //   } else if (status == 1) {
    //     return '发货';
    //   } else {
    //     return '查看';
    //   }
    // },
    //按钮显示样式
    // getBtn(status) {
    //   if (status == 0) {
    //     return false;
    //   } else if (status == 1) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
  },
}
</script>

<style scoped lang="scss">
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;
    }

    // header {
    // display: flex;
    // justify-content: flex-end;

    .right {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }

      .el-date-editor {
        margin-left: 10px;
        width: 250px;
      }

      // .select {
      // display: flex;
      // justify-content: space-between;

      /deep/ .el-date-editor {
        background-color: #f2f2f2;
        border: none;
        border-radius: 10px;
      }

      /deep/.el-range-input {
        background-color: #f2f2f2;
      }

      // }
    }

    // }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: "";
      position: absolute;
      background-color: #ebeef5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }
  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>
